import {
  Card,
  Layout,
  SkeletonBodyText,
  SkeletonPage,
} from '@shopify/polaris-internal';
import React from 'react';

import {PageFooterHelp, SkeletonPageActions} from '~/components';

export function FilterDetailsSkeleton() {
  return (
    <SkeletonPage>
      <Layout>
        <Layout.Section>
          <Card>
            <SkeletonBodyText lines={3} />
          </Card>
        </Layout.Section>

        <Layout.Section>
          <SkeletonPageActions />
        </Layout.Section>
      </Layout>
      <PageFooterHelp resourceName="filters" />
    </SkeletonPage>
  );
}
